<template>
    <game-items-edit game-item-variant="skins">
        <template #title>{{ $t('skins.edit') }}</template>
    </game-items-edit>
</template>

<script>
import GameItemsEdit from '@/views/game-items/game-items-edit/GameItemsEdit.vue';

export default {
    components: {
        GameItemsEdit,
    },
};
</script>

<style lang="scss" scoped></style>
